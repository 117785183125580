import {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
  useMemo,
} from "react";
import style from "./PausePanel.module.scss";
import { v4 } from "uuid";
import { BackgroundDiv } from "../../comm/global/Styleds";
import AppConfig from "../../AppConfig";
import { useGlobalAudioPlayer, useAudioPlayer } from "react-use-audio-player";
const VoicePanel = ({ mute, pause, show, updateStage }) => {
  const clickAudio = useAudioPlayer();
  useEffect(() => {
    pause.current = true;
  }, []);
  return (
    <div className={style.pausePanel}>
      <div className={style.bg}>
        <h1>聆聽音檔</h1>
        <span>請先點擊「播放」按鈕聆聽音檔，再選取答案</span>
        <div className={style.btns}>
          <div
            className={style.continue}
            onClick={(e) => {
              clickAudio.load("./audio/btn.wav", {
                autoplay: true,
                volume: mute ? 0 : 1,
              });
              pause.current = false;
              show(false);
            }}
          >
            <span>確定</span>
          </div>
        </div>
        <div
          className={style.closeBtn}
          onClick={(e) => {
            clickAudio.load("./audio/btn.wav", {
              autoplay: true,
              volume: mute ? 0 : 1,
            });
            pause.current = false;
            show(false);
          }}
        >
          <img src="./game1/xmark_outline_bold.svg" />
        </div>
      </div>
    </div>
  );
};

export default VoicePanel;
