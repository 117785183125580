import { useState, useEffect } from "react";

import { useGlobalAudioPlayer } from "react-use-audio-player";

import style from "./History2.module.scss";
import { BackgroundDiv } from "../../comm/global/Styleds";
import AppConfig from "../../AppConfig";
import VoiceIcon from "../../comm/global/components/VoiceIcon";

const History2 = ({
  anserHistory,
  AllOptionKey,
  allQuestion,
  enterResultFromHistory,
}) => {
  const { load } = useGlobalAudioPlayer();

  const [result, set_result] = useState(null);
  const [audioPlayingIndex, set_audioPlayingIndex] = useState(-1);

  useEffect(() => {
    console.log("anserHistory", anserHistory, AllOptionKey, allQuestion);
    const currentQuestion = allQuestion[0];
    let options = [];
    AllOptionKey.forEach((key) => {
      if (
        currentQuestion &&
        currentQuestion[key] &&
        currentQuestion[key] !== ""
      ) {
        options.push(currentQuestion[key]);
      }
    });
    const group = currentQuestion.optionGroupName.split(",");
    const anserZone = currentQuestion.questionAnswerData
      .split("/")
      .map((part) => part.split(",").map(Number));
    console.log("options", options);
    console.log("group", group);
    console.log("anserZone", anserZone);
    set_result({ options, group, anserZone, anserHistory });
  }, [AllOptionKey, allQuestion, anserHistory]);

  if (result)
    return (
      <div className={style.history}>
        <BackgroundDiv className={style.bg} img="./game2/history_bg.png">
          <div className={style.title}>
            <span>正確答案</span>
          </div>
          <div className={style.tip}>
            <div>
              <img src="./game2/right.svg" alt="right icon" />
              <span>答對</span>
            </div>
            <div>
              <img src="./game2/wrong.svg" alt="wrong icon" />
              <span>答錯</span>
            </div>
          </div>
          <div className={style.content}>
            {result.anserZone.map((group, k) => {
              return (
                <div className={style.item} style={{}} key={k}>
                  <div className={style.groupName}>{result.group[k]}</div>
                  <div className={style.anser}>
                    {group.map((ansId, _k) => {
                      const anserObj = result.anserHistory.find(
                        (v) => v.id === ansId,
                      );
                      //215 346
                      console.log(anserObj);
                      if (anserObj.type === "voice") {
                        return (
                          <div
                            className={style.option}
                            key={_k}
                            onClick={(e) => {
                              set_audioPlayingIndex(anserObj.id);
                              load(`${AppConfig.baseUrl}${anserObj.value}`, {
                                autoplay: true,
                                volume: 1,
                                onend: (e) => {
                                  console.log("done");
                                  set_audioPlayingIndex(-1);
                                },
                              });
                            }}
                          >
                            <VoiceIcon
                              isPlay={anserObj.id === audioPlayingIndex}
                            />
                            {`選項${anserObj.number}`}
                            {anserObj.isCorrect === true ? (
                              <img
                                id="right"
                                className={style.rightImg}
                                src="./game2/right.svg"
                                alt="right icon"
                              />
                            ) : (
                              <img
                                id="wrong"
                                className={style.wrongImg}
                                src="./game2/wrong.svg"
                                alt="wrong icon"
                              />
                            )}
                          </div>
                        );
                      } else if (
                        anserObj.type === "text" ||
                        anserObj.type === "text_mps"
                      ) {
                        return (
                          <div className={style.option} key={_k}>
                            {result.options[anserObj.id - 1]}
                            {anserObj.isCorrect === true ? (
                              <img
                                id="right"
                                className={style.rightImg}
                                src="./game2/right.svg"
                                alt="right icon"
                              />
                            ) : (
                              <img
                                id="wrong"
                                className={style.wrongImg}
                                src="./game2/wrong.svg"
                                alt="wrong icon"
                              />
                            )}
                          </div>
                        );
                      } else if (anserObj.type === "img") {
                        return (
                          <div className={style.option} key={_k}>
                            <img
                              src={`${AppConfig.baseUrl}${result.options[anserObj.id - 1]}`}
                              style={{ width: "18vw", aspectRatio: "4/3" }}
                              alt="option img"
                            />
                            {anserObj.isCorrect === true ? (
                              <img
                                id="right"
                                className={style.rightImg}
                                src="./game2/right.svg"
                                alt="right icon"
                              />
                            ) : (
                              <img
                                id="wrong"
                                className={style.wrongImg}
                                src="./game2/wrong.svg"
                                alt="wrong icon"
                              />
                            )}
                          </div>
                        );
                      } else return <></>;
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </BackgroundDiv>
        <div className={style.backDiv}>
          <div
            className={style.back}
            onClick={(e) => {
              enterResultFromHistory();
            }}
          >
            <span>返回</span>
          </div>
        </div>
      </div>
    );
};

export default History2;
