export const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;
  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
};

export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getFontFamily = (type) => {
  switch (type) {
    case "text": //漢
      return "Iansui";
      break;
    case "mps": //注
      return "BpmfZihiOnly";
      break;
    case "text_mps": //漢注
      return "BpmfIansui";
      break;
  }
};

export const getGrade = (
  totalQuestions,
  correctAnswers,
  totalTime,
  usedTime,
) => {
  const accuracy = (correctAnswers / totalQuestions) * 100;
  let timeUsage = (usedTime / totalTime) * 100;
  if (totalTime === 0) timeUsage = 0;
  if (accuracy === 100 && timeUsage < 80) {
    return "S";
  } else if (accuracy >= 90 && accuracy < 100) {
    return "A";
  } else if (accuracy === 100 && timeUsage >= 80) {
    return "A";
  } else if (accuracy >= 60 && accuracy < 90) {
    return "B";
  } else if (accuracy >= 30 && accuracy < 60) {
    return "C";
  } else {
    return "D";
  }
};
