import { useState, useEffect } from "react";

import style from "./History.module.scss";
import { BackgroundDiv } from "../../comm/global/Styleds";
import AppConfig from "../../AppConfig";
import { getFontFamily } from "../../comm/comm";
import Game14VoiceBtn from "../../comm/global/components/Game14VoiceBtn";
import { syncService } from "../../comm/global/services/SyncService";
import { SyncEvent } from "../../comm/global/SyncEvent";

/**
 * @typedef {Object} HistoryProps
 * @property {Array} anserHistory
 * @property {Array} allQuestion
 * @property {() => void} enterResultFromHistory
 * @property {(index: string) => void} openAnswerDetail
 */

/**
 * @param {HistoryProps} props
 * @returns {React.JSX.Element}
 */
const History = ({
  anserHistory,
  allQuestion,
  openAnswerDetail,
  enterResultFromHistory,
}) => {
  const [audioPlayingIndex, set_audioPlayingIndex] = useState("");

  useEffect(() => {
    console.log("...", allQuestion, anserHistory);
  }, [allQuestion, anserHistory]);

  return (
    <div className={style.history}>
      <BackgroundDiv className={style.bg} img="./game1/history_bg.png">
        <div className={style.title}>
          <span>正確答案</span>
        </div>
        <div className={style.content}>
          {anserHistory.map((v, k) => {
            const _question = allQuestion[v.questionIndex];
            return (
              <div className={style.item} key={k}>
                {/* 1 */}
                <span className={style.number}>{`${k + 1}.`}</span>
                {/* 2 */}
                <img
                  className={style.icon}
                  src={v.isCorrect ? "./comm/v.svg" : "./comm/x.svg"}
                  alt="is correct icon"
                />
                <QuestionDiv
                  type={_question?.descriptionType}
                  subType={_question[`optionType1`]}
                  value={_question?.questionDescription}
                  index={k}
                  audioPlayingIndex={audioPlayingIndex}
                  set_audioPlayingIndex={set_audioPlayingIndex}
                />
                <AnswerDiv
                  type={_question[`optionType1`]}
                  subType={_question?.descriptionType}
                  value={_question[`option${_question.questionAnswerData}`]}
                  index={k}
                  audioPlayingIndex={audioPlayingIndex}
                  set_audioPlayingIndex={set_audioPlayingIndex}
                />

                <div
                  className={style.detailBtn}
                  onClick={() => {
                    openAnswerDetail(k);
                    syncService.sendMessage(SyncEvent.OPEN_ANSWER_DETAIL, k);
                  }}
                >
                  <img src="./comm/zoom_solid.svg" alt="zoom icon" />
                </div>
              </div>
            );
          })}
        </div>
      </BackgroundDiv>
      <div className={style.backDiv}>
        <div
          className={style.back}
          onClick={() => {
            enterResultFromHistory();
            syncService.sendMessage(SyncEvent.ENTER_RESULT_FROM_HISTORY);
          }}
        >
          <span>返回</span>
        </div>
      </div>
    </div>
  );
};

const QuestionDiv = ({
  type,
  subType,
  value,
  index,
  audioPlayingIndex,
  set_audioPlayingIndex,
}) => {
  if (["text", "text_mps", "mps"].includes(type)) {
    return (
      <span
        className={`${style.questionText} ${["img", "voice"].includes(subType) ? style.scale : ""}`}
        style={{ fontFamily: getFontFamily(type) }}
      >
        {value}
      </span>
    );
  } else if (type === "img") {
    return (
      <img
        className={style.questionImg}
        src={`${AppConfig.baseUrl}${value}`}
        alt="baseUrl value"
      />
    );
  } else if (type === "voice") {
    return (
      <Game14VoiceBtn
        type="question"
        value={value}
        index={index}
        audioPlayingIndex={audioPlayingIndex}
        set_audioPlayingIndex={set_audioPlayingIndex}
      />
    );
  }
};

const AnswerDiv = ({
  type,
  subType,
  value,
  index,
  audioPlayingIndex,
  set_audioPlayingIndex,
}) => {
  console.log("value", value);

  if (["text", "text_mps", "mps"].includes(type)) {
    return (
      <span
        className={`${style.answerText} ${["img", "voice"].includes(subType) ? style.scale : ""}`}
        style={{ fontFamily: getFontFamily(type) }}
      >
        {value}
      </span>
    );
  } else if (type === "img") {
    return (
      <img
        className={style.answerImg}
        src={`${AppConfig.baseUrl}${value}`}
        alt="baseUrl value"
      />
    );
  } else if (type === "voice") {
    return (
      <Game14VoiceBtn
        type="answer"
        value={value}
        index={index}
        audioPlayingIndex={audioPlayingIndex}
        set_audioPlayingIndex={set_audioPlayingIndex}
      />
    );
  }
};

export default History;
