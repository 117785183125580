import { useState, useEffect, useRef, useCallback } from "react";

import { useGlobalAudioPlayer } from "react-use-audio-player";

import style from "./Game.module.scss";
import { BackgroundDiv } from "../../comm/global/Styleds";
import AppConfig from "../../AppConfig";
import Countdown from "../Countdown";
import Entrance from "./Entrance";
import Start from "./Start";
import PausePanel from "./PausePanel";
import Result from "./Result";
import History from "./History";
import Game1 from "./Game1";
import Game2 from "./Game2";
import Game3 from "./Game3";
import History2 from "./History2";
import History3 from "./History3";
import History4 from "./History4";
import AnserDetail from "./AnserDetail";
import RestartPanel from "./RestartPanel";
import VoicePanel from "./VoicePanel";
import CountdownTimer from "./CountdownTimer";
import GameBtn from "../../comm/global/components/GameBtn";
import { syncService } from "../../comm/global/services/SyncService";
import { SyncEvent } from "../../comm/global/SyncEvent";

const AllOptionKey = [
  "option1",
  "option2",
  "option3",
  "option4",
  "option5",
  "option6",
  "option7",
  "option8",
];

let timer = null;
const speedMincroSec = AppConfig.speedMincroSec;

const Game = ({ data, questionData }) => {
  const { load } = useGlobalAudioPlayer();
  const prev_stage = useRef("");
  const [stage, set_stage] = useState("entrance"); //entrance start game result history
  const timeLimit = useRef(3);
  const [gaming, set_gaming] = useState(false);
  const [isLoading, set_isLoading] = useState(true);
  const [showCountdown, set_showCountdown] = useState(false);
  const [currentComplete, set_currentComplete] = useState(0);
  const [questionNum, set_questionNum] = useState(0);
  const [rightCount, set_rightCount] = useState(0);
  const [time] = useState(999);
  const leftTime = useRef(0);
  const [allQuestion, set_allQuestion] = useState([]);
  const [anserHistory, set_anserHistory] = useState([]); //{isCorrect:false,questionIndex:0,selOptionId:2}
  const [mute, set_mute] = useState(false);
  const [showAnserDetail, set_showAnserDetail] = useState(-1);
  const [show_pause, set_show_pause] = useState(false);
  const [show_voice, set_show_voice] = useState(false);
  const [show_restart, set_show_restart] = useState(false);
  const pause = useRef(false);
  const [scale, setScale] = useState(1);
  const [end, set_end] = useState(false);
  const countdownRef = useRef();

  const toggleAudio = useCallback(() => {
    load("./audio/btn.wav", {
      autoplay: true,
      volume: mute ? 0 : 1,
    });
    set_mute((prev) => !prev);
  }, [load, mute]);

  const updateStage = useCallback((newStage) => {
    set_stage((prev) => {
      if (prev !== newStage) prev_stage.current = prev;
      return newStage;
    });
  }, []);

  const enterGame = useCallback(() => {
    updateStage("start");
  }, [updateStage]);

  const startGame = useCallback(() => {
    set_showCountdown(true);
  }, []);

  const enterPausePanel = useCallback(() => {
    load("./audio/btn.wav", {
      autoplay: true,
      volume: mute ? 0 : 1,
    });
    pause.current = true;
    set_show_pause(true);
  }, [mute, load]);

  const resumeGameFromPausePanel = useCallback(() => {
    load("./audio/btn.wav", {
      autoplay: true,
      volume: mute ? 0 : 1,
    });
    pause.current = false;
    set_show_pause(false);
  }, [mute, load]);

  const restartGameFromResumePanel = useCallback(() => {
    load("./audio/btn.wav", {
      autoplay: true,
      volume: mute ? 0 : 1,
    });
    set_show_pause(false);
    updateStage("start");
  }, [mute, updateStage, load]);

  const enterRestartPanel = useCallback(() => {
    load("./audio/btn.wav", {
      autoplay: true,
      volume: mute ? 0 : 1,
    });
    set_show_restart(true);
  }, [mute, load]);

  const resumeGameFromRestartPanel = useCallback(() => {
    load("./audio/btn.wav", {
      autoplay: true,
      volume: mute ? 0 : 1,
    });
    set_show_restart(false);
    pause.current = false;
  }, [mute, load]);

  const restartGameFromRestartPanel = useCallback(() => {
    load("./audio/btn.wav", {
      autoplay: true,
      volume: mute ? 0 : 1,
    });
    set_show_restart(false);
    updateStage("start");
  }, [mute, updateStage, load]);

  const enterResult = useCallback(() => {
    load("./audio/btn.wav", {
      autoplay: true,
      volume: mute ? 0 : 1,
    });
    pause.current = true;
    set_end(true);
  }, [mute, load]);

  const enterResultFromGame2 = useCallback(() => {
    updateStage("result");
  }, [updateStage]);

  const enterResultFromGame3 = useCallback(() => {
    load("./audio/btn.wav", {
      autoplay: true,
      volume: mute ? 0 : 1,
    });
    updateStage("result");
  }, [load, mute, updateStage]);

  const restartGameFromResult = useCallback(() => {
    load("./audio/btn.wav", {
      autoplay: true,
      volume: mute ? 0 : 1,
    });
    updateStage("start");
  }, [load, updateStage, mute]);

  const enterHistory = useCallback(() => {
    load("./audio/btn.wav", {
      autoplay: true,
      volume: mute ? 0 : 1,
    });
    updateStage("history");
  }, [load, updateStage, mute]);

  const enterResultFromHistory = useCallback(() => {
    load("./audio/btn.wav", {
      autoplay: true,
      volume: mute ? 0 : 1,
    });
    updateStage("result");
  }, [load, mute, updateStage]);

  const openAnswerDetail = useCallback((index) => {
    set_showAnserDetail(index);
  }, []);

  const closeAnswerDetail = useCallback(() => {
    load("./audio/btn.wav", {
      autoplay: true,
      volume: mute ? 0 : 1,
    });
    set_showAnserDetail(-1);
  }, [load, mute]);

  useEffect(() => {
    const cleanup = syncService.onMessage((event, payload) => {
      switch (event) {
        case SyncEvent.TOGGLE_AUDIO:
          toggleAudio();
          break;
        case SyncEvent.ENTER_GAME:
          enterGame();
          break;
        case SyncEvent.START_GAME:
          startGame();
          break;
        case SyncEvent.ENTER_RESULT:
          enterResult();
          break;
        case SyncEvent.ENTER_RESULT_FROM_GAME_2:
          enterResultFromGame2();
          break;
        case SyncEvent.ENTER_RESULT_FROM_GAME_3:
          enterResultFromGame3();
          break;
        case SyncEvent.ENTER_PAUSE_PANEL:
          enterPausePanel();
          break;
        case SyncEvent.RESUME_GAME_FROM_PAUSE_PANEL:
          resumeGameFromPausePanel();
          break;
        case SyncEvent.RESTART_GAME_FROM_PAUSE_PANEL:
          restartGameFromResumePanel();
          break;
        case SyncEvent.ENTER_RESTART_PANEL:
          enterRestartPanel();
          break;
        case SyncEvent.RESUME_GAME_FROM_RESTART_PANEL:
          resumeGameFromRestartPanel();
          break;
        case SyncEvent.RESTART_GAME_FROM_RESTART_PANEL:
          restartGameFromRestartPanel();
          break;
        case SyncEvent.ENTER_HISTORY:
          enterHistory();
          break;
        case SyncEvent.RESTART_GAME_FROM_RESULT:
          restartGameFromResult();
          break;
        case SyncEvent.ENTER_RESULT_FROM_HISTORY:
          enterResultFromHistory();
          break;
        case SyncEvent.OPEN_ANSWER_DETAIL:
          openAnswerDetail(payload);
          break;
        case SyncEvent.CLOSE_ANSWER_DETAIL:
          closeAnswerDetail();
          break;
        default:
          break;
      }
    });

    return () => {
      cleanup();
    };
  }, [
    toggleAudio,
    enterGame,
    startGame,
    enterResult,
    enterResultFromGame2,
    enterResultFromGame3,
    enterPausePanel,
    resumeGameFromPausePanel,
    restartGameFromResumePanel,
    enterRestartPanel,
    resumeGameFromRestartPanel,
    restartGameFromRestartPanel,
    enterHistory,
    restartGameFromResult,
    enterResultFromHistory,
    openAnswerDetail,
    closeAnswerDetail,
  ]);

  // Init time limit
  useEffect(() => {
    timeLimit.current = questionData.reduce(
      (accumulator, currentValue) => accumulator + parseInt(currentValue.time),
      0,
    );
    set_allQuestion(questionData);
  }, [questionData]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const currentAspectRatio = width / height;
      const targetAspectRatio = 8 / 5;
      let newScale = 1;
      if (currentAspectRatio > targetAspectRatio) {
        newScale = targetAspectRatio / currentAspectRatio;
      }
      setScale(newScale);
    };

    // 初始化时调用一次
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (allQuestion.length > 0) set_isLoading(false);
  }, [allQuestion]);

  useEffect(() => {
    switch (stage) {
      case "start":
        set_currentComplete(0);
        set_end(false);
        break;
      case "result":
        set_currentComplete(0);
        break;
      case "game":
        set_anserHistory([]);
        set_currentComplete(0);
        set_rightCount(0);
        set_showCountdown(false);
        set_gaming(true);
        pause.current = false;
        break;
      default:
        break;
    }
  }, [stage]);

  if (isLoading) return <></>;
  else {
    return (
      <>
        <BackgroundDiv
          className={style.app}
          img={`./game${data.type}/bg.png`}
          style={{
            transform: `scale(${scale})`,
            transformOrigin: "center center",
            overflow: "hidden",
          }}
        >
          {data.type === "1" && (
            <img src="./game1/bgani.png" className={style.bgani} alt="bgani" />
          )}
          {data.type === "4" && (
            <>
              <img
                src="./game4/fire1.png"
                className={style.fire1}
                alt="fire 1 icon"
              />
              <img
                src="./game4/fire2.svg"
                className={style.fire1}
                alt="fire 2 icon"
              />
              <img
                src="./game4/fire3.png"
                className={style.fire1}
                alt="fire 3 icon"
              />
              <img
                src="./game4/fire4.svg"
                className={style.fire1}
                alt="fire 4 icon"
              />
            </>
          )}

          {showAnserDetail !== -1 && (
            <>
              {(data.type === "1" || data.type === "4") && (
                <AnserDetail
                  gameData={data}
                  mute={mute}
                  anserHistory={anserHistory}
                  allQuestion={allQuestion}
                  showAnserDetail={showAnserDetail}
                  closeAnswerDetail={closeAnswerDetail}
                />
              )}
            </>
          )}

          {stage === "history" && (
            <>
              {data.type === "1" && (
                <History
                  anserHistory={anserHistory}
                  allQuestion={allQuestion}
                  openAnswerDetail={openAnswerDetail}
                  enterResultFromHistory={enterResultFromHistory}
                />
              )}
              {data.type === "2" && (
                <History2
                  anserHistory={anserHistory}
                  AllOptionKey={AllOptionKey}
                  allQuestion={allQuestion}
                  enterResultFromHistory={enterResultFromHistory}
                />
              )}
              {data.type === "3" && (
                <History3
                  anserHistory={anserHistory}
                  AllOptionKey={AllOptionKey}
                  allQuestion={allQuestion}
                  enterResultFromHistory={enterResultFromHistory}
                />
              )}
              {data.type === "4" && (
                <History4
                  gameData={data}
                  anserHistory={anserHistory}
                  allQuestion={allQuestion}
                  openAnswerDetail={openAnswerDetail}
                  enterResultFromHistory={enterResultFromHistory}
                />
              )}
            </>
          )}

          {stage === "result" && (
            <Result
              mute={mute}
              prev_stage={prev_stage}
              questionNum={questionNum}
              rightCount={rightCount}
              timeLimit={timeLimit}
              time={leftTime.current}
              data={data}
              enterHistory={enterHistory}
              restartGameFromResult={restartGameFromResult}
            />
          )}

          {stage === "game" && (
            <div
              className={style.game}
              style={{
                visibility: show_pause || show_restart ? "hidden" : "visible",
              }}
            >
              <div className={style.headerBar}>
                <div className={style.left}>
                  {["1", "2", "4"].includes(data.type) && (
                    <div
                      className={style.doneBtn}
                      onClick={() => {
                        enterResult();
                        syncService.sendMessage(SyncEvent.ENTER_RESULT);
                      }}
                    >
                      <span>結束作答</span>
                    </div>
                  )}
                  <div
                    className={style.cmdBtn}
                    onClick={() => {
                      toggleAudio();
                      syncService.sendMessage(SyncEvent.TOGGLE_AUDIO);
                    }}
                  >
                    {mute ? (
                      <img src="./comm/audio_mute.svg" alt="audio mute icon" />
                    ) : (
                      <img src="./comm/audio.svg" alt="audio mute icon" />
                    )}
                  </div>
                  <div
                    className={style.cmdBtn}
                    onClick={() => {
                      enterRestartPanel();
                      syncService.sendMessage(SyncEvent.ENTER_RESTART_PANEL);
                    }}
                  >
                    <img src="./comm/undo_solid_thick.svg" alt="undo icon" />
                  </div>

                  <div
                    className={style.cmdBtn}
                    onClick={() => {
                      enterPausePanel();
                      syncService.sendMessage(SyncEvent.ENTER_PAUSE_PANEL);
                    }}
                  >
                    <img src="./comm/pause_solid.svg" alt="pause icon" />
                  </div>
                </div>
                {["1", "4"].includes(data.type) && (
                  <div className={style.center}>
                    <span>{`完成度 ${currentComplete}/${allQuestion.length}`}</span>
                  </div>
                )}
                <div className={style.right}>
                  {["1", "4"].includes(data.type) && (
                    <div>
                      <img src="./game1/game_check.svg" alt="game check icon" />
                      <span>{rightCount}</span>
                    </div>
                  )}
                  {data.hasTimer === "1" && (
                    <div>
                      <img src="./game1/game_timer.svg" alt="game timer icon" />
                      {data.hasTimer === "1" && (
                        <CountdownTimer
                          ref={countdownRef}
                          totalSeconds={timeLimit.current}
                          pauseRef={pause}
                          leftTime={leftTime}
                          onFinish={() => {
                            set_end(true);
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
              {(data.type === "1" || data.type === "4") && (
                <Game1
                  end={end}
                  mute={mute}
                  time={time}
                  data={data}
                  pause={pause}
                  set_show_voice={set_show_voice}
                  speedMincroSec={speedMincroSec}
                  currentComplete={currentComplete}
                  set_currentComplete={set_currentComplete}
                  AllOptionKey={AllOptionKey}
                  allQuestion={allQuestion}
                  updateStage={updateStage}
                  gaming={gaming}
                  set_gaming={set_gaming}
                  timer={timer}
                  set_anserHistory={set_anserHistory}
                  set_rightCount={set_rightCount}
                  set_questionNum={set_questionNum}
                />
              )}
              {data.type === "2" && (
                <Game2
                  end={end}
                  mute={mute}
                  time={time}
                  pause={pause}
                  data={data}
                  AllOptionKey={AllOptionKey}
                  allQuestion={allQuestion}
                  set_questionNum={set_questionNum}
                  set_rightCount={set_rightCount}
                  set_anserHistory={set_anserHistory}
                  enterResultFromGame2={enterResultFromGame2}
                />
              )}
              {data.type === "3" && (
                <Game3
                  end={end}
                  pause={pause}
                  AllOptionKey={AllOptionKey}
                  allQuestion={allQuestion}
                  set_questionNum={set_questionNum}
                  set_rightCount={set_rightCount}
                  set_anserHistory={set_anserHistory}
                  enterResultFromGame3={enterResultFromGame3}
                />
              )}
            </div>
          )}

          {stage === "entrance" && (
            <Entrance
              title={data.title}
              gameType={data.type}
              descriptionType={data.descriptionType}
              mute={mute}
              enterGame={enterGame}
            />
          )}

          {stage === "start" && (
            <Start
              mute={mute}
              gameType={data.type}
              descriptionType={data.descriptionType}
              gameDescriptionImg={data.gameDescriptionImg}
              gameDescriptionText={data.gameDescriptionText}
              startGame={startGame}
            />
          )}

          {showCountdown && (
            <Countdown
              mute={mute}
              doneCb={(e) => {
                setTimeout(() => {
                  updateStage("game");
                }, 1000);
              }}
            />
          )}

          {show_pause && (
            <PausePanel
              resumeGameFromPausePanel={resumeGameFromPausePanel}
              restartGameFromPausePanel={restartGameFromResumePanel}
            />
          )}

          {show_restart && (
            <RestartPanel
              resumeGameFromRestartPanel={resumeGameFromRestartPanel}
              restartGameFromRestartPanel={restartGameFromRestartPanel}
            />
          )}

          {show_voice && (
            <VoicePanel
              mute={mute}
              pause={pause}
              show={set_show_voice}
              updateStage={updateStage}
            />
          )}

          <GameBtn />
        </BackgroundDiv>
      </>
    );
  }
};

export default Game;
