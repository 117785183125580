export const SyncEvent = {
  ENTER_GAME: "enter-game",
  START_GAME: "start-game",
  ENTER_PAUSE_PANEL: "enter-pause-panel",
  RESUME_GAME_FROM_PAUSE_PANEL: "resume-game-from-pause-panel",
  RESTART_GAME_FROM_PAUSE_PANEL: "restart-game-from-pause-panel",
  ENTER_RESTART_PANEL: "enter-restart-panel",
  RESUME_GAME_FROM_RESTART_PANEL: "resume-game-from-restart-panel",
  RESTART_GAME_FROM_RESTART_PANEL: "restart-game-from-restart-panel",
  ENTER_RESULT: "enter-result",
  ENTER_RESULT_FROM_GAME_2: "enter-result-from-game-2",
  ENTER_RESULT_FROM_GAME_3: "enter-result-from-game-3",
  RESTART_GAME_FROM_RESULT: "restart-game-from-result",
  ENTER_HISTORY: "enter-history",
  ENTER_RESULT_FROM_HISTORY: "enter-result-from-history",
  OPEN_ANSWER_DETAIL: "open-answer-detail",
  CLOSE_ANSWER_DETAIL: "close-answer-detail",
  TOGGLE_AUDIO: "toggle-audio",

  GAME_1_CLICK_OPTION: "game-1-click-option",
  GAME_2_MOVE_ITEM: "game-2-move-item",
  GAME_2_SUBMIT: "game-2-submit",
  GAME_3_SUBMIT: "game-3-submit",
  GAME_3_MOVE_ITEM: "game-3-move-item",
};
Object.freeze(SyncEvent);
