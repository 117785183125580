import { useState, useEffect, useRef, useCallback } from "react";

import { useGlobalAudioPlayer } from "react-use-audio-player";

import style from "./Game1.module.scss";
import { BackgroundDiv } from "../../comm/global/Styleds";
import AppConfig from "../../AppConfig";
import { getFontFamily, shuffle } from "../../comm/comm";
import ApngImg from "../../comm/global/components/ApngImg";
import LottieAnimation from "../../comm/global/components/LottieAnimation";
import CountdownTimer from "./CountdownTimer";
import { syncService } from "../../comm/global/services/SyncService";
import { SyncEvent } from "../../comm/global/SyncEvent";

let playedVoice = new Set([]);

const Game1 = ({
  end,
  data,
  pause,
  set_show_voice,
  set_questionNum,
  set_currentComplete,
  AllOptionKey,
  allQuestion,
  updateStage,
  gaming,
  set_gaming,
  timer,
  mute,
  set_anserHistory,
  set_rightCount,
}) => {
  const { load } = useGlobalAudioPlayer();

  const [questionList, setQuestionList] = useState(null);
  const questionListRef = useRef(null);
  const CountdownTimerRef = useRef(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [options, set_options] = useState([]);
  const [selOption, set_selOption] = useState({});
  const [audioPlayingIndex, set_audioPlayingIndex] = useState(-1);
  const [audioPlaying, set_audioPlaying] = useState(false);

  const [showRight, set_showRight] = useState(false);
  const [showWrong, set_showWrong] = useState(false);
  const [checking, set_checking] = useState(false);

  const toEnd = useCallback(() => {
    setTimeout(() => {
      set_anserHistory((prev) => {
        const arr = [...prev];
        console.log("arr", arr);
        questionListRef.current.forEach((id) => {
          arr.push({ isCorrect: false, questionIndex: id, selOptionId: null });
        });
        return arr;
      });
      set_gaming(false);
      clearInterval(timer);
      updateStage("result");
    }, 1000);
  }, [set_anserHistory, set_gaming, timer, updateStage]);

  useEffect(() => {
    generateQuestionList(allQuestion.length, data.isRandom === "1");
    set_questionNum(allQuestion.length);
    set_selOption(null);
  }, [allQuestion.length, data.isRandom, set_questionNum]);

  useEffect(() => {
    if (end === true) {
      toEnd();
    }
  }, [end, toEnd]);

  useEffect(() => {
    if (!allQuestion[currentQuestion]) return;
    load("./audio/card.wav", {
      autoplay: true,
      volume: mute ? 0 : 1,
    });
    set_audioPlayingIndex(-1);
    let _options = [];
    AllOptionKey.forEach((key) => {
      if (
        allQuestion[currentQuestion] &&
        allQuestion[currentQuestion][key] &&
        allQuestion[currentQuestion][key] !== ""
      ) {
        const id = parseInt(key.replace("option", ""));
        _options.push({
          id,
          type: allQuestion[currentQuestion][`optionType${id}`],
          value: allQuestion[currentQuestion][key],
        });
        console.log(
          "allQuestion[currentQuestion]",
          allQuestion[currentQuestion],
        );
      }
    });
    if (allQuestion[currentQuestion].optionRandom === "1")
      _options = shuffle(_options);

    console.log("set_options 1");
    set_options(_options);
    pause.current = false;
    //count down
    if (CountdownTimerRef.current) CountdownTimerRef.current.reset();
  }, [currentQuestion, allQuestion, AllOptionKey, mute, load, pause]);

  useEffect(() => {
    if (questionList) {
      console.log("questionList", questionList.length);
      if (questionList.length > 0) {
        setCurrentQuestion(questionList[0]);
        set_selOption(null);
      } else {
        //game end
        set_gaming(false);
        clearInterval(timer);
        setTimeout(() => {
          updateStage("result");
        }, 1200);
      }
    }
    questionListRef.current = questionList;
  }, [questionList, set_gaming, timer, updateStage]);

  const generateQuestionList = (number, isRandom) => {
    let questions = Array.from({ length: number }, (_, i) => i);
    if (isRandom) {
      questions = questions.sort(() => Math.random() - 0.5);
    }
    console.log("questions", questions);
    setQuestionList(questions);
    console.log("number, isRandom", number, isRandom, questions[0]);
    setCurrentQuestion(questions[0]);
  };

  const handleAnswer = useCallback(
    (isCorrect, selOptionId) => {
      // Play audio if not muted
      console.log("~~~");
      pause.current = true;
      set_audioPlaying(false);
      if (!mute && selOptionId !== null) {
        const audioSrc = isCorrect ? "./audio/right.wav" : "./audio/worng.wav";
        new Audio(audioSrc).play();
      }
      if (selOptionId !== null) {
        if (isCorrect) set_showRight(true);
        else set_showWrong(true);
      }
      set_anserHistory((prev) => [
        ...prev,
        { isCorrect, questionIndex: currentQuestion, selOptionId },
      ]);

      if (selOptionId !== null) {
        if (isCorrect) set_rightCount((prev) => prev + 1);
      } else setQuestionList((prevList) => prevList.slice(1));
      set_currentComplete((prev) => prev + 1);
      playedVoice.clear();
    },
    [
      pause,
      mute,
      set_anserHistory,
      currentQuestion,
      set_currentComplete,
      set_rightCount,
    ],
  );

  const onClickOption = useCallback(
    (option) => {
      console.log("gaming,checking", gaming, checking);
      if (!gaming || checking) {
        load("./audio/btn_disable.wav", {
          autoplay: true,
          volume: mute ? 0 : 1,
        });
      } else {
        const isCorrect =
          option.id ===
          parseInt(allQuestion[currentQuestion]["questionAnswerData"]);
        handleAnswer(isCorrect, option.id);
        load("./audio/btn.wav", {
          autoplay: true,
          volume: mute ? 0 : 1,
        });

        set_selOption({ id: option.id, isCorrect: isCorrect });
        set_checking(true);
      }
    },
    [allQuestion, currentQuestion, gaming, checking, mute, load, handleAnswer],
  );

  useEffect(() => {
    const cleanup = syncService.onMessage((event, payload) => {
      switch (event) {
        case SyncEvent.GAME_1_CLICK_OPTION:
          onClickOption(payload);
          break;
        default:
          break;
      }
    });
    return () => {
      cleanup();
    };
  }, [onClickOption]);

  return (
    <div className={style.questionPanel}>
      <BackgroundDiv
        className={style.gameBg}
        img={`./game${data.type}/game_bg.png`}
      >
        {data.hasTimer === "1" && (
          <div className={style.countdown}>
            {allQuestion[currentQuestion] && (
              <CountdownTimer
                type={2}
                ref={CountdownTimerRef}
                totalSeconds={parseInt(allQuestion[currentQuestion].time)}
                pauseRef={pause}
                onFinish={() => {
                  console.log("-----");
                  handleAnswer(false, null);
                }}
              />
            )}
          </div>
        )}
        {["text", "text_mps", "mps"].includes(
          allQuestion[currentQuestion]?.descriptionType,
        ) && (
          <div className={style.textType}>
            <span
              style={{
                fontFamily: getFontFamily(
                  allQuestion[currentQuestion]?.descriptionType,
                ),
              }}
            >
              {allQuestion[currentQuestion]?.questionDescription}
            </span>
          </div>
        )}
        {allQuestion[currentQuestion]?.descriptionType === "img" && (
          <div className={style.imgType}>
            <img
              src={`${AppConfig.baseUrl}${allQuestion[currentQuestion]?.questionDescription}`}
              alt="question description"
            />
          </div>
        )}
        {allQuestion[currentQuestion]?.descriptionType === "voice" && (
          <div className={style.voiceType}>
            <div
              className={style.btn}
              onClick={(e) => {
                set_audioPlaying(true);
                load(
                  `${AppConfig.baseUrl}${allQuestion[currentQuestion]?.questionDescription}`,
                  {
                    autoplay: true,
                    volume: mute ? 0 : 1,
                    onend: (e) => {
                      console.log("done");
                      set_audioPlaying(false);
                    },
                  },
                );
              }}
            >
              {audioPlaying ? (
                <div style={{ width: "4vw" }}>
                  <LottieAnimation color="white" />
                </div>
              ) : (
                <img src="./comm/play_b.svg" alt="play b icon" />
              )}
            </div>
            <span>播放題目</span>
          </div>
        )}
        {showRight && (
          <div className={style.checkAni}>
            <ApngImg
              src={`./game1/right.png`}
              loopTimes={1}
              delaySec={0}
              autoPlay={true}
              doneCb={(e) => {
                set_showRight(false);
                setQuestionList((prevList) => prevList.slice(1));
                set_checking(false);
              }}
            />
          </div>
        )}
        {showWrong && (
          <div className={style.checkAni}>
            <ApngImg
              src={`./game1/wrong.png`}
              loopTimes={1}
              delaySec={0}
              autoPlay={true}
              doneCb={(e) => {
                set_showWrong(false);
                setQuestionList((prevList) => prevList.slice(1));
                set_checking(false);
              }}
            />
          </div>
        )}
      </BackgroundDiv>
      <BackgroundDiv
        className={style.selBg}
        img={`./game${data.type}/game_sel_bg.png`}
      >
        <div className={style.tip}>
          {["text", "text_mps", "mps"].includes(options[0]?.type) ? (
            <span style={{ fontFamily: getFontFamily(options[0]?.type) }}>
              請於下方選取正確答案
            </span>
          ) : (
            <span>請於下方選取正確答案</span>
          )}
        </div>
        <div className={`${style.options} ${style.customScrollbar}`}>
          {options.map((option, k) => {
            let resultStyle = "";
            if (selOption?.id === option.id) {
              if (selOption.isCorrect) resultStyle = style.correct;
              else resultStyle = style.wrong;
            }
            if (
              option.type === "text" ||
              option.type === "text_mps" ||
              option.type === "mps"
            ) {
              return (
                <button
                  className={`${style.option} ${resultStyle}`}
                  key={k}
                  onClick={() => {
                    onClickOption(option);
                    syncService.sendMessage(
                      SyncEvent.GAME_1_CLICK_OPTION,
                      option,
                    );
                  }}
                >
                  <span style={{ fontFamily: getFontFamily(option.type) }}>
                    {option.value}
                  </span>
                </button>
              );
            } else if (option.type === "img") {
              return (
                <button
                  className={`${style.option} ${resultStyle}`}
                  key={k}
                  onClick={() => {
                    onClickOption(option);
                    syncService.sendMessage(
                      SyncEvent.GAME_1_CLICK_OPTION,
                      option,
                    );
                  }}
                >
                  <img
                    src={`${AppConfig.baseUrl}${option.value}`}
                    alt="option value"
                    style={{ width: "60%", aspectRatio: "4/3" }}
                  />
                </button>
              );
            } else if (option.type === "voice") {
              return (
                <div className={`${style.optionAudio}`} key={k}>
                  <div
                    className={style.audioBtn}
                    onClick={(e) => {
                      set_audioPlayingIndex(k);
                      playedVoice.add(k);
                      load(`${AppConfig.baseUrl}${option.value}`, {
                        autoplay: true,
                        volume: mute ? 0 : 1,
                        onend: (e) => {
                          console.log("done");
                          set_audioPlayingIndex(-1);
                        },
                      });
                    }}
                  >
                    {k === audioPlayingIndex ? (
                      <div style={{ width: "2vw" }}>
                        <LottieAnimation color="brown" />
                      </div>
                    ) : (
                      <img src="./comm/play.svg" alt="play icon" />
                    )}
                    <span>播放</span>
                  </div>
                  <div
                    className={`${style.optionBtn}  ${resultStyle}`}
                    onClick={() => {
                      if (playedVoice.has(k)) {
                        onClickOption(option);
                        syncService.sendMessage(
                          SyncEvent.GAME_1_CLICK_OPTION,
                          option,
                        );
                        set_audioPlayingIndex(-1);
                      } else {
                        set_show_voice(true);
                      }
                    }}
                  >
                    <span
                      style={{ fontFamily: getFontFamily(option.type) }}
                    >{`選項${k + 1}`}</span>
                  </div>
                </div>
              );
            } else {
              return <></>;
            }
          })}
        </div>
      </BackgroundDiv>
    </div>
  );
};

export default Game1;
