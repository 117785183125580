import { useState, useEffect } from "react";

import { useGlobalAudioPlayer } from "react-use-audio-player";

import style from "./Entrance.module.scss";
import { BackgroundDiv } from "../../comm/global/Styleds";
import AppConfig from "../../AppConfig";
import { getFontFamily } from "../../comm/comm";
import { syncService } from "../../comm/global/services/SyncService";
import { SyncEvent } from "../../comm/global/SyncEvent";

const soundFiles = [
  "./audio/gamestart.wav",
  "./audio/btn.wav",
  "./audio/A.wav",
  "./audio/B.wav",
  "./audio/C.wav",
  "./audio/D.wav",
  "./audio/result.wav",
  "./audio/321.mp3",
  "./audio/go.mp3",
];

/**
 * @typedef {Object} EntranceProps
 * @property {string} title
 * @property {string} gameType
 * @property {string} descriptionType
 * @property {boolean} mute
 * @property {() => void} enterGame
 */

/**
 * @param {EntranceProps} props
 * @returns {React.JSX.Element}
 */
const Entrance = ({ title, gameType, descriptionType, mute, enterGame }) => {
  const { load } = useGlobalAudioPlayer();
  const [loaded, set_loaded] = useState(false);

  useEffect(() => {
    const handleUserInteraction = async () => {
      const loadPromises = soundFiles.map((file) => {
        return new Promise((resolve, reject) => {
          const audio = new Audio(file);
          audio.addEventListener("loadeddata", () => {
            resolve(); // 音频加载完成
          });
          audio.addEventListener("error", () => {
            reject(new Error(`Failed to load audio: ${file}`));
          });
          audio.load(); // 手动加载音频文件
        });
      });

      try {
        await Promise.all(loadPromises);
        set_loaded(true); // 所有音频加载完成
      } catch (error) {
        console.error(error);
      }
    };
    handleUserInteraction();
  }, []);

  let titleBoxStyle = style.game1;
  switch (gameType) {
    case "2":
      titleBoxStyle = style.game2;
      break;
    case "3":
      titleBoxStyle = style.game3;
      break;
    case "4":
      titleBoxStyle = style.game4;
      break;
    default:
      break;
  }

  if (loaded)
    return (
      <>
        <BackgroundDiv
          className={style.entrance}
          img={`./game${gameType}/entrance_frame.png`}
        >
          <div className={`${style.titleBox} ${titleBoxStyle}`}>
            <span>{AppConfig.type[parseInt(gameType) - 1].name}</span>
            <span style={{ fontFamily: getFontFamily(descriptionType) }}>
              {title}
            </span>
          </div>
          <div className={style.demoBox}>
            {gameType === "2" || gameType === "3" ? (
              <img src={`./game${gameType}/info.gif`} alt="game info gif" />
            ) : (
              <img
                src={`./game${gameType}/entrance_info.png`}
                alt="entrance info"
              />
            )}
            <img
              src={`./game1/Robot_floating.png`}
              alt="robot floating mascot"
            />
          </div>
        </BackgroundDiv>

        <div
          test-id="entrance-btn"
          className={style.enterBtn}
          onClick={(e) => {
            load("./audio/btn.wav", {
              autoplay: true,
              volume: mute ? 0 : 1,
            });
            enterGame();
            syncService.sendMessage(SyncEvent.ENTER_GAME);
          }}
        >
          <span>進入遊戲</span>
        </div>
      </>
    );
  else
    return (
      <div className={style.loadingPanel}>
        <img src="./game1/Robot_head.png" alt="robot head" />
        <span>{`資源載入中⋯`}</span>
      </div>
    );
};

export default Entrance;
