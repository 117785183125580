import { useState, useEffect } from "react";

import LZString from "lz-string";

import style from "./App.module.scss";
import Game from "./pages/game/Game";
import { syncService } from "./comm/global/services/SyncService";

export default function App() {
  const [data, setData] = useState(null);
  const [questionData, set_questionData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const cleanup = syncService.init();
    return () => cleanup();
  }, []);

  // Init game data and question data
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("gameCode");

    const decompressedString = LZString.decompressFromEncodedURIComponent(code);
    try {
      console.log(decompressedString);
      const jsonData = JSON.parse(decompressedString);

      console.log("--jsonData", jsonData);
      let questionIDs = "";
      const questionList = [];
      jsonData.questions.forEach((v, k) => {
        console.log(v);
        questionIDs += `ch${k}`;
        if (k + 1 < jsonData.questions.length) questionIDs += `/`;

        let questionAnswerData = "";
        switch (jsonData.type) {
          case 1:
          case 4:
            questionAnswerData = v.answer[0][0];
            break;
          case 2:
            questionAnswerData = v.answer.join("/");
            break;
          case 3:
            questionAnswerData = v.answer[0].join(",");
            break;
          default:
            break;
        }

        let obj = {
          questionId: v.id,
          time: v.time.toString(),
          optionRandom: v.optionRandom ? "1" : "0",
          descriptionType: v.descriptionType,
          questionDescription: v.description,
          questionAnswerPicture: v.questionAnswerPicture,
          questionAnswerData: questionAnswerData.toString(),
          optionGroupName: v.optionGroup ? v.optionGroup.join(",") : "",
        };
        v.options.forEach((option, optionIndex) => {
          obj[`optionType${option.index}`] = option.type ? option.type : "text";
          obj[`option${option.index}`] = option.content;
        });
        questionList.push(obj);
      });

      let gameConfig = {
        gameId: jsonData.gameId,
        grade: jsonData.grade,
        subject: jsonData.subject,
        questionIDs: questionIDs,
        type: jsonData.type.toString(),
        title: jsonData.title,
        gameDescriptionText: jsonData.description,
        gameDescriptionImg: jsonData.gameDescriptionImg,
        descriptionType: jsonData.descriptionType,
        isRandom: jsonData.isRandom ? "1" : "0",
        hasTimer: jsonData.hasTimer ? "1" : "0",
      };

      console.log("gameConfig", gameConfig);
      console.log("questionList", questionList);

      if (gameConfig?.gameId) {
        setData(gameConfig);
        set_questionData(questionList);
      }
    } catch (error) {
      console.log(error);
      setError(error);
    }
  }, []);

  return (
    <div className={style.app}>
      {error && <div>404 Page Not Found</div>}
      {data && <Game data={data} questionData={questionData} />}
    </div>
  );
}
