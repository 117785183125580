import React, { useEffect, useState } from "react";
import style from "./Countdown.module.scss";
import AppConfig from "../AppConfig";
import { useAudioPlayer, useGlobalAudioPlayer } from "react-use-audio-player";
const Countdown = ({ mute, doneCb }) => {
  const player2 = useAudioPlayer();
  const [currentImage, setCurrentImage] = useState(null);
  const timeStep = AppConfig.debug ? 1 : 1000;
  useEffect(() => {
    const images = [
      "./comm/start_num3.svg",
      "./comm/start_num2.svg",
      "./comm/start_num1.svg",
      "./comm/start_go.svg",
    ];
    const sounds = [
      "./audio/321.mp3",
      "./audio/321.mp3",
      "./audio/321.mp3",
      "./audio/go.mp3",
    ];
    let index = 0;

    const showImage = () => {
      if (index < images.length) {
        setCurrentImage(images[index]);
        if (mute === false) {
          player2.load(sounds[index], {
            autoplay: true,
            volume: mute ? 0 : 1,
          });
        }
        index++;
        setTimeout(() => {
          setCurrentImage(null);
        }, timeStep * 0.9);
      }
    };

    const interval = setInterval(() => {
      showImage();
      if (index >= images.length) {
        clearInterval(interval);
        doneCb();
      }
    }, timeStep);
    showImage();

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={style.container}>
      {currentImage && (
        <img src={currentImage} className={style.image} alt="countdown" />
      )}
    </div>
  );
};

export default Countdown;
