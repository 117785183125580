import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import AppConfig from "../../AppConfig";
import ProgressBar from "../ProgressBar";

const CountdownTimer = forwardRef(
  ({ type = 1, totalSeconds, pauseRef, leftTime, onFinish }, ref) => {
    const [time, set_time] = useState(totalSeconds * 1000); // 將秒數轉換成毫秒
    const timerRef = useRef(null); // 用來保存計時器

    const startTimer = () => {
      timerRef.current = setInterval(() => {
        if (!pauseRef.current) {
          set_time((prev) => {
            if (prev - AppConfig.speedMincroSec * 10 <= 0) {
              clearInterval(timerRef.current);
              if (onFinish) onFinish();
              return 0;
            } else {
              return prev - AppConfig.speedMincroSec * 10;
            }
          });
        }
      }, 10);
    };

    // 開始倒數計時
    useEffect(() => {
      startTimer();
      return () => clearInterval(timerRef.current); // 清理計時器
    }, []);

    useEffect(() => {
      if (leftTime !== undefined) leftTime.current = time;
    }, [time]);

    useImperativeHandle(ref, () => ({
      reset() {
        set_time(totalSeconds * 1000); // 重置為初始時間
        clearInterval(timerRef.current); // 清理已存在的計時器
        startTimer(); // 重新啟動計時器
      },
    }));

    const formatTime = (ms) => {
      const seconds = Math.floor(ms / 1000);
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
    };

    if (type === 1) return <span>{formatTime(time)}</span>;
    else if (type === 2)
      return (
        <>
          <div>
            <span>{`倒數${parseInt(time / 1000)}秒`}</span>
          </div>
          <div>
            <ProgressBar percentage={time / (totalSeconds * 1000)} />
          </div>
        </>
      );
  },
);

export default CountdownTimer;
