import { syncService } from "../../comm/global/services/SyncService";
import { SyncEvent } from "../../comm/global/SyncEvent";
import style from "./PausePanel.module.scss";

/**
 * @typedef {Object} PausePanelProps
 * @property {() => void} resumeGameFromPausePanel
 * @property {() => void} restartGameFromPausePanel
 */

/**
 * @param {PausePanelProps} props
 * @returns {React.JSX.Element}
 */
const PausePanel = ({
  resumeGameFromPausePanel,
  restartGameFromPausePanel,
}) => {
  return (
    <div className={style.pausePanel}>
      <div className={style.bg}>
        <h1>遊戲暫停中</h1>
        <span>要繼續遊戲嗎?</span>
        <div className={style.btns}>
          <div
            className={style.undo}
            onClick={() => {
              restartGameFromPausePanel();
              syncService.sendMessage(SyncEvent.RESTART_GAME_FROM_PAUSE_PANEL);
            }}
          >
            <span>重新開始</span>
          </div>
          <div
            className={style.continue}
            onClick={() => {
              resumeGameFromPausePanel();
              syncService.sendMessage(SyncEvent.RESUME_GAME_FROM_PAUSE_PANEL);
            }}
          >
            <span>繼續遊戲</span>
          </div>
        </div>

        <div
          className={style.closeBtn}
          onClick={() => {
            resumeGameFromPausePanel();
            syncService.sendMessage(SyncEvent.RESUME_GAME_FROM_PAUSE_PANEL);
          }}
        >
          <img src="./game1/xmark_outline_bold.svg" alt="outline bold" />
        </div>
      </div>
    </div>
  );
};

export default PausePanel;
