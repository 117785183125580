import { useState, useEffect } from "react";

import { useGlobalAudioPlayer } from "react-use-audio-player";

import style from "./AnserDetail.module.scss";
import { BackgroundDiv } from "../../comm/global/Styleds";
import AppConfig from "../../AppConfig";
import { getFontFamily } from "../../comm/comm";
import Game14VoiceBtn from "../../comm/global/components/Game14VoiceBtn";
import LottieAnimation from "../../comm/global/components/LottieAnimation";
import { syncService } from "../../comm/global/services/SyncService";
import { SyncEvent } from "../../comm/global/SyncEvent";

const AnserDetail = ({
  gameData,
  mute,
  anserHistory,
  allQuestion,
  showAnserDetail,
  closeAnswerDetail,
}) => {
  const { load } = useGlobalAudioPlayer();
  const targetQuestion =
    allQuestion[anserHistory[showAnserDetail].questionIndex];
  const [audioPlaying, set_audioPlaying] = useState(false);
  const [audioPlayingIndex, set_audioPlayingIndex] = useState("");

  useEffect(() => {
    console.log("gameData", anserHistory);
  }, [anserHistory]);

  return (
    <div className={style.AnserDetail}>
      <BackgroundDiv
        className={style.bg}
        img={
          gameData.type === "1"
            ? "./game1/anserRecord.png"
            : "./game4/anserRecord.png"
        }
      >
        {["text", "text_mps", "mps"].includes(
          targetQuestion?.descriptionType,
        ) && (
          <div className={style.textType}>
            <span>{targetQuestion?.questionDescription}</span>
          </div>
        )}
        {["img"].includes(targetQuestion?.descriptionType) && (
          <div className={style.imgType}>
            <img
              src={`${AppConfig.baseUrl}${targetQuestion?.questionDescription}`}
              alt="question description"
            />
          </div>
        )}
        {["voice"].includes(targetQuestion?.descriptionType) && (
          <div className={style.voiceType}>
            <div
              className={style.btn}
              onClick={(e) => {
                set_audioPlaying(true);
                load(
                  `${AppConfig.baseUrl}${targetQuestion?.questionDescription}`,
                  {
                    autoplay: true,
                    volume: mute ? 0 : 1,
                    onend: (e) => {
                      console.log("done");
                      set_audioPlaying(false);
                    },
                  },
                );
              }}
            >
              {audioPlaying ? (
                <div style={{ width: "4vw" }}>
                  <LottieAnimation color="white" />
                </div>
              ) : (
                <img src="./comm/play_b.svg" alt="play b icon" />
              )}
            </div>
            <span>播放題目</span>
          </div>
        )}
        <div className={style.content}>
          <div className={style.numberBox}>
            <div>
              <span>題號</span>
            </div>
            <div>
              <span>{showAnserDetail + 1}</span>
            </div>
          </div>
          <div className={style.checkBox}>
            <div>
              <span>回答結果</span>
            </div>
            <div>
              <img
                src={
                  anserHistory[showAnserDetail].isCorrect
                    ? "./comm/v.svg"
                    : "./comm/x.svg"
                }
                style={{ width: "3vw" }}
                alt="is correct icon"
              />
            </div>
          </div>
          {anserHistory[showAnserDetail].isCorrect === false && (
            <div className={style.myAnswerBox}>
              <div>
                <span>我的答案</span>
              </div>
              <div /* style={{ alignItems: anserHistory[showAnserDetail].selOptionId === null ? "center" : "flex-start" }} */
              >
                {anserHistory[showAnserDetail].selOptionId === null ? (
                  <span
                    style={{
                      fontFamily: getFontFamily(targetQuestion[`optionType1`]),
                      color: "red",
                    }}
                  >
                    未做答
                  </span>
                ) : (
                  <>
                    {["text", "text_mps", "mps"].includes(
                      targetQuestion[
                        `optionType${targetQuestion.questionAnswerData}`
                      ],
                    ) && (
                      <span
                        style={{
                          fontFamily: getFontFamily(
                            targetQuestion[
                              `optionType${anserHistory[showAnserDetail].selOptionId}`
                            ],
                          ),
                        }}
                      >
                        {
                          targetQuestion[
                            `option${anserHistory[showAnserDetail].selOptionId}`
                          ]
                        }
                      </span>
                    )}
                    {targetQuestion[
                      `optionType${targetQuestion.questionAnswerData}`
                    ] === "img" && (
                      <img
                        src={`${AppConfig.baseUrl}${targetQuestion[`option${anserHistory[showAnserDetail].selOptionId}`]}`}
                        style={{
                          width: "8vw",
                          aspectRatio: "4/3",
                          borderRadius: "1.4vw",
                          objectFit: "contain",
                        }}
                        alt="target question"
                      />
                    )}
                    {targetQuestion[
                      `optionType${targetQuestion.questionAnswerData}`
                    ] === "voice" && (
                      <Game14VoiceBtn
                        type="answer"
                        index="1"
                        customStyle={{ marginLeft: "5%" }}
                        value={
                          targetQuestion[
                            `option${anserHistory[showAnserDetail].selOptionId}`
                          ]
                        }
                        audioPlayingIndex={audioPlayingIndex}
                        set_audioPlayingIndex={set_audioPlayingIndex}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          )}
          <div className={style.answerBox}>
            <div>
              <span>正確答案</span>
            </div>
            <div>
              {["text", "text_mps", "mps"].includes(
                targetQuestion[
                  `optionType${targetQuestion.questionAnswerData}`
                ],
              ) && (
                <span
                  style={{
                    fontFamily: getFontFamily(
                      targetQuestion[
                        `optionType${targetQuestion.questionAnswerData}`
                      ],
                    ),
                  }}
                >
                  {targetQuestion[`option${targetQuestion.questionAnswerData}`]}
                </span>
              )}
              {targetQuestion[
                `optionType${targetQuestion.questionAnswerData}`
              ] === "img" && (
                <img
                  src={`${AppConfig.baseUrl}${targetQuestion[`option${targetQuestion.questionAnswerData}`]}`}
                  alt="question answer data"
                  style={{
                    width: "8vw",
                    aspectRatio: "4/3",
                    borderRadius: "1.4vw",
                    objectFit: "contain",
                  }}
                />
              )}
              {targetQuestion[
                `optionType${targetQuestion.questionAnswerData}`
              ] === "voice" && (
                <Game14VoiceBtn
                  type="answer"
                  index="2"
                  customStyle={{ marginLeft: "5%" }}
                  value={
                    targetQuestion[`option${targetQuestion.questionAnswerData}`]
                  }
                  audioPlayingIndex={audioPlayingIndex}
                  set_audioPlayingIndex={set_audioPlayingIndex}
                />
              )}
            </div>
          </div>
        </div>
        <div className={style.closeBtnDiv}>
          <div
            className={style.closeBtn}
            onClick={() => {
              closeAnswerDetail();
              syncService.sendMessage(SyncEvent.CLOSE_ANSWER_DETAIL);
            }}
          >
            <span>關閉</span>
          </div>
        </div>
      </BackgroundDiv>
    </div>
  );
};

export default AnserDetail;
