import React, { useEffect, useRef } from "react";
import lottie from "lottie-web";
import animationData_blue from "./audio_ani_blue.json"; // 替換為你的 Lottie JSON 文件的路徑
import animationData_brown from "./audio_ani_brown.json"; // 替換為你的 Lottie JSON 文件的路徑
import animationData_white from "./audio_ani_white.json"; // 替換為你的 Lottie JSON 文件的路徑

const LottieAnimation = ({ color }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    let data = animationData_blue;
    switch (color) {
      case "blue":
        data = animationData_blue;
        break;
      case "brown":
        data = animationData_brown;
        break;
      case "white":
        data = animationData_white;
        break;
    }
    const animationInstance = lottie.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: data, // 這是你的 Lottie 動畫數據
    });

    // 清除動畫資源
    return () => {
      animationInstance.destroy();
    };
  }, []);

  return <div ref={containerRef} style={{ width: "100%", height: "100%" }} />;
};

export default LottieAnimation;
