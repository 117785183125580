import {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
  useMemo,
  Component,
} from "react";
import style from "./GameBtn.module.scss";
import { v4 } from "uuid";
const GameBtn = ({ children }) => {
  const btnRef = useRef(null);

  useEffect(() => {
    const defaultSize = btnRef.current.getBoundingClientRect();
    console.log("btnRef.current", btnRef.current);
    btnRef.current.style.minHeight = `${defaultSize.height}px`;
    setTimeout(() => {}, 1000);
  }, []);

  return (
    <div className={style.btnBox} ref={btnRef}>
      {children}
    </div>
  );
};

export default GameBtn;
