import React from "react";
import ReactDOM from "react-dom/client";
import "./index.module.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
const originalLog = console.log;
// 重写 console.log 函数
console.log = function (...message) {};
console.warn = function (...message) {};
console.error = function (...message) {};
Number.prototype.toMS = function (x) {
  const ms = this;
  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / (1000 * 60)) % 60);
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  return formattedTime;
};
Number.prototype.toZH = function () {
  const numMap = [
    "零",
    "一",
    "二",
    "三",
    "四",
    "五",
    "六",
    "七",
    "八",
    "九",
    "十",
  ];
  const num = this.valueOf();

  if (num >= 0 && num <= 10) {
    return numMap[num];
  }

  return "範圍僅支持0到10";
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
