import {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
  useMemo,
} from "react";
import style from "./VoiceIcon.module.scss";
import { v4 } from "uuid";
import LottieAnimation from "./LottieAnimation";
const VoiceIcon = ({ width = "4vw", color = "blue", isPlay }) => {
  return (
    <div className={style.audioIcon} style={{ width: width }}>
      {isPlay ? (
        <LottieAnimation color={color} />
      ) : (
        <img className={style.audioImg} src="./comm/play2.svg" />
      )}
    </div>
  );
};

export default VoiceIcon;
