import { useEffect } from "react";

import { useGlobalAudioPlayer } from "react-use-audio-player";

import style from "./Start.module.scss";
import { BackgroundDiv } from "../../comm/global/Styleds";
import AppConfig from "../../AppConfig";
import { getFontFamily } from "../../comm/comm";
import { syncService } from "../../comm/global/services/SyncService";
import { SyncEvent } from "../../comm/global/SyncEvent";

/**
 * @typedef {Object} StartProps
 * @property {boolean} mute
 * @property {string} gameType
 * @property {string} descriptionType
 * @property {string} gameDescriptionImg Game description image URL
 * @property {string} gameDescriptionText
 * @property {() => void} startGame
 */

/**
 * @param {StartProps} props
 * @returns {React.JSX.Element}
 */
const Start = ({
  mute,
  gameType,
  descriptionType,
  gameDescriptionImg,
  gameDescriptionText,
  startGame,
}) => {
  const { load } = useGlobalAudioPlayer();

  let titleBoxStyle = style.game1;
  switch (gameType) {
    case "2":
      titleBoxStyle = style.game2;
      break;
    case "3":
      titleBoxStyle = style.game3;
      break;
    case "4":
      titleBoxStyle = style.game4;
      break;
    default:
      break;
  }

  useEffect(() => {
    load("./audio/gamestart.wav", {
      autoplay: true,
      volume: mute ? 0 : 1,
      loop: true,
    });
  }, [load, mute]);

  return (
    <BackgroundDiv
      className={style.start}
      img={`./game${gameType}/start_bg.png`}
    >
      <div className={style.titleBox}>
        <img src="./game1/Robot_head_s.svg" alt="robot head icon" />
        <div className={`${style.infoBox} ${titleBoxStyle}`}>
          <div className={style.infoArrow}></div>
          <div className={style.infoContent}>
            <span style={{ fontFamily: getFontFamily(descriptionType) }}>
              {gameDescriptionText}
            </span>
          </div>
        </div>
      </div>

      <img
        src={`${AppConfig.baseUrl}${gameDescriptionImg}`}
        alt="game description"
      />

      <div className={style.startBtnDiv}>
        <div
          className={style.startBtn}
          onClick={(e) => {
            load("./audio/btn.wav", {
              autoplay: true,
              volume: mute ? 0 : 1,
            });
            startGame();
            syncService.sendMessage(SyncEvent.START_GAME);
          }}
        >
          <span>開始遊戲</span>
        </div>
      </div>
    </BackgroundDiv>
  );
};

export default Start;
