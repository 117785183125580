import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%; /* 可以根據需要調整 */
  background-color: grey;
  border-radius: 10px;
  position: relative;
`;

const Filler = styled.div`
  width: ${(props) => props.percentage * 100}%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  //transition: width 0.1s ease-in-out;
`;

const ProgressBar = ({ percentage }) => {
  return (
    <Container>
      <Filler percentage={percentage} />
    </Container>
  );
};

export default ProgressBar;
