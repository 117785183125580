import { useState, useEffect, useRef, memo, useCallback } from "react";

import { DndProvider, useDrag, useDrop, useDragLayer } from "react-dnd";
import { getEmptyImage, HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { useGlobalAudioPlayer } from "react-use-audio-player";

import style from "./Game2.module.scss";
import { BackgroundDiv } from "../../comm/global/Styleds";
import { getFontFamily, shuffle, wait } from "../../comm/comm";
import AppConfig from "../../AppConfig";
import LottieAnimation from "../../comm/global/components/LottieAnimation";
import { syncService } from "../../comm/global/services/SyncService";
import { SyncEvent } from "../../comm/global/SyncEvent";

const ItemType = "ITEM";

const Game2 = ({
  end,
  mute,
  time,
  pause,
  AllOptionKey,
  allQuestion,
  set_questionNum,
  set_rightCount,
  set_anserHistory,
  enterResultFromGame2,
}) => {
  const { load } = useGlobalAudioPlayer();
  const [items, setItems] = useState(null);
  const itemsRef = useRef(null);
  const [group, set_group] = useState([]);
  const [state, set_state] = useState("gaming"); //check, result
  const [dragEnabled, setDragEnabled] = useState(true);
  const currentQuestion = allQuestion[0];
  const [audioPlayingIndex, set_audioPlayingIndex] = useState(-1);
  const [cardAniDone, set_cardAniDone] = useState(false);

  const moveItem = useCallback((dragObj, to) => {
    setItems((prevItems) => {
      const from = dragObj.location;
      const newItems = { ...prevItems };
      newItems[from] = newItems[from].filter((i) => i !== dragObj.item);
      newItems[to] = [...newItems[to], dragObj.item];
      return newItems;
    });
  }, []);

  const moveItemSync = useCallback(
    (dragObj, to) => {
      moveItem(dragObj, to);
      syncService.sendMessage(SyncEvent.GAME_2_MOVE_ITEM, { dragObj, to });
    },
    [moveItem],
  );

  useEffect(() => {
    const cleanup = syncService.onMessage(
      SyncEvent.GAME_2_MOVE_ITEM,
      (event, { dragObj, to }) => {
        if (event === SyncEvent.GAME_2_MOVE_ITEM) {
          moveItem(dragObj, to);
        }
      },
    );
    return () => cleanup();
  }, [moveItem]);

  useEffect(() => {
    setDragEnabled(time > 0);
  }, [time]);

  useEffect(() => {
    itemsRef.current = items;
  }, [items]);

  useEffect(() => {
    let options = [];
    AllOptionKey.forEach((key) => {
      if (
        currentQuestion &&
        currentQuestion[key] &&
        currentQuestion[key] !== ""
      ) {
        const id = parseInt(key.replace("option", ""));
        options.push({
          id: id,
          value: currentQuestion[key],
          type: currentQuestion[`optionType${id}`],
        });
      }
    });
    options = shuffle(options);
    options.forEach((v, k) => {
      v.number = k + 1;
    });

    const group = currentQuestion.optionGroupName.split(",");
    set_group(group);
    const items = {};
    group.forEach((v, k) => {
      items[`group_${k}`] = [];
    });
    set_questionNum(options.length);
    items.x = options;
    setItems(items);

    setTimeout(() => {
      set_cardAniDone(true);
    }, 4000);
  }, [allQuestion, set_questionNum, AllOptionKey, currentQuestion]);

  const submit = useCallback(async () => {
    set_audioPlayingIndex(-1);
    load("./audio/btn.wav", {
      autoplay: true,
      volume: mute ? 0 : 1,
    });
    set_state("check");
    pause.current = true;
    setDragEnabled(false); // 禁止拖曳
    const anserZone = currentQuestion.questionAnswerData
      .split("/")
      .map((part) => part.split(",").map(Number));
    let rightCount = 0;
    const _anserHistory = [];
    for (let groupIndex = 0; groupIndex < anserZone.length; groupIndex++) {
      for (let itemIndex = 0; ; itemIndex++) {
        console.log("~~~", anserZone, groupIndex, itemIndex, itemsRef.current);
        const em = document.querySelector(`#group_${groupIndex}_${itemIndex}`);
        if (em) {
          await wait(1000);
          em.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
          });
          const _item = itemsRef.current[`group_${groupIndex}`][itemIndex];
          if (anserZone[groupIndex].includes(_item.id)) {
            rightCount++;
            _anserHistory.push({
              isCorrect: true,
              id: _item.id,
              number: _item.number,
              value: _item.value,
              type: _item.type,
            });

            em.classList.add(style.showRightImg);
          } else {
            _anserHistory.push({
              isCorrect: false,
              id: _item.id,
              number: _item.number,
              value: _item.value,
              type: _item.type,
            });
            em.classList.add(style.showWrongImg);
          }
        } else {
          break;
        }
      }
    }
    setItems((prevItems) => {
      prevItems.x.forEach((v) => {
        _anserHistory.push({
          isCorrect: false,
          id: v.id,
          number: v.number,
          value: v.value,
          type: v.type,
        });
      });
      return prevItems;
    });
    set_anserHistory(_anserHistory);
    set_rightCount(rightCount);
    set_state("result");
  }, [currentQuestion, set_rightCount, set_anserHistory, mute, load, pause]);

  const submitSync = useCallback(async () => {
    submit();
    syncService.sendMessage(SyncEvent.GAME_2_SUBMIT);
  }, [submit]);

  useEffect(() => {
    if (end === true) {
      submitSync();
    }
  }, [end, submitSync]);

  function getCardBackImg(len) {
    console.log("len", len);
    switch (len) {
      case 2:
        return "./game2/Section2.png";
      case 3:
        return "./game2/Section3.png";
      case 4:
        return "./game2/Section4.png";
      case 5:
        return "./game2/Section5.png";
      case 6:
        return "./game2/Section6.png";
      default:
        break;
    }
  }

  if (items) {
    return (
      <div className={style.app}>
        <DndProvider
          backend={
            "ontouchstart" in window || navigator.maxTouchPoints > 0
              ? TouchBackend
              : HTML5Backend
          }
        >
          <BackgroundDiv
            className={style.topContainer}
            img="./game2/game_bg.png"
          >
            {Object.keys(items)
              .filter((key) => key.startsWith("group_"))
              .map((key, k) => {
                return (
                  <div key={k} className={style[`flipCard_${k}`]}>
                    <div className={style.flipCardInner}>
                      <div
                        className={style.zoneBox}
                        style={{
                          position: cardAniDone ? "relative" : "absolute",
                        }}
                      >
                        <div className={style.header}>
                          <span>{group[k]}</span>
                          <div className={style.count}>
                            <span>{items[key].length}</span>
                          </div>
                        </div>
                        <DropZone
                          id={key}
                          items={items[key]}
                          moveItem={moveItemSync}
                          dragEnabled={dragEnabled}
                          audioPlayingIndex={audioPlayingIndex}
                          set_audioPlayingIndex={set_audioPlayingIndex}
                          group={group}
                        />
                      </div>
                      <div className={style.backCard}>
                        <img
                          src={getCardBackImg(group.length)}
                          alt="group length"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </BackgroundDiv>
          <BackgroundDiv
            className={style.bottomContainer}
            img="./game2/game_sel_bg.png"
          >
            <img src="./game2/sel_tip.png" alt="sel tip" />
            <DropZone
              id="x"
              items={items.x}
              moveItem={moveItemSync}
              dragEnabled={dragEnabled}
              audioPlayingIndex={audioPlayingIndex}
              set_audioPlayingIndex={set_audioPlayingIndex}
              group={group}
            />
            {(items.x.length === 0 || time <= 0) && state === "gaming" && (
              <div className={style.submitPanel}>
                <div className={style.submit} onClick={submitSync}>
                  <span>開始對答</span>
                </div>
              </div>
            )}

            {state === "check" && (
              <div className={style.titleBox}>
                <img src="./game1/Robot_head.png" alt="robot head" />
                <div className={`${style.infoBox} `}>
                  <div className={style.infoArrow}></div>
                  <div className={style.infoContent}>
                    <span>對答中．．．</span>
                  </div>
                </div>
              </div>
            )}
            {state === "result" && (
              <div className={style.nextStage}>
                <div
                  className={style.nextStageBtn}
                  onClick={() => enterResultFromGame2()}
                >
                  <span>查看遊戲結果</span>
                </div>
              </div>
            )}
          </BackgroundDiv>
          <CustomDragLayer />
        </DndProvider>
      </div>
    );
  }
};

const DropZone = ({
  id,
  items,
  moveItem,
  dragEnabled,
  audioPlayingIndex,
  set_audioPlayingIndex,
}) => {
  const [hoveredItem, setHoveredItem] = useState(null);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemType,
    drop: (item) => {
      moveItem(item, id);
      setHoveredItem(null);
    },
    hover: (item) => {
      setHoveredItem(item.item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isItemInZone =
    hoveredItem && items.some((i) => i.id === hoveredItem.id);
  console.log("items", items);

  return (
    <div ref={drop} className={style.dropZone}>
      {items.map((item, k) => (
        <DraggableItem
          id={`${id}_${k}`}
          key={item.id}
          item={item}
          location={id}
          dragEnabled={dragEnabled}
          audioPlayingIndex={audioPlayingIndex}
          set_audioPlayingIndex={set_audioPlayingIndex}
        />
      ))}
      {isOver && canDrop && (
        <>
          {["text", "text_mps", "mps"].includes(hoveredItem?.type) && (
            <div
              className={style.draggableItem}
              style={{ opacity: isItemInZone ? 0 : 0.5 }}
            >
              <span style={{ fontFamily: getFontFamily(hoveredItem.type) }}>
                {hoveredItem.value}
              </span>
            </div>
          )}
          {hoveredItem?.type === "voice" && (
            <div
              className={style.draggableItem}
              style={{ opacity: isItemInZone ? 0 : 0.5 }}
            >
              <img
                className={style.audioImg}
                src="./comm/play2.png"
                alt="play 2"
              />
              {`選項${hoveredItem.number}`}
            </div>
          )}
          {hoveredItem?.type === "img" && (
            <div
              className={`${style.draggableItem} ${style.imgType}`}
              style={{ opacity: isItemInZone ? 0 : 0.5 }}
            >
              <img
                src={`${AppConfig.baseUrl}${hoveredItem.value}`}
                alt="hovered item value"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const CustomDragLayer = () => {
  const { item, isDragging, clientOffset } = useDragLayer((monitor) => ({
    itemType: monitor.getItemType(),
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    clientOffset: monitor.getClientOffset(),
  }));
  if (!isDragging) {
    return null;
  }
  const rect = document
    .querySelector("#root > div > div")
    .getBoundingClientRect();

  if (item.item.type === "voice") {
    return (
      <div
        className={`${style.floatDraggableItem}`}
        style={{
          position: "fixed",
          pointerEvents: "none",
          left: clientOffset.x - rect.x,
          top: clientOffset.y - rect.y,
        }}
      >
        <img
          className={style.audioImg}
          src="./comm/play2.svg"
          alt="play 2 icon"
        />
        <span>{`選項${item.item.number}`}</span>
        <img
          id="right"
          className={style.rightImg}
          src="./game2/right.svg"
          alt="right icon"
        />
        <img
          id="wrong"
          className={style.wrongImg}
          src="./game2/wrong.svg"
          alt="wrong icon"
        />
      </div>
    );
  } else if (["img"].includes(item.item.type)) {
    return (
      <div
        className={`${style.floatDraggableItem} ${style.imgType}`}
        style={{
          position: "fixed",
          pointerEvents: "none",
          left: clientOffset.x - rect.x,
          top: clientOffset.y - rect.y,
        }}
      >
        <img
          src={`${AppConfig.baseUrl}${item.item.value}`}
          alt="item value"
          style={{ pointerEvents: "none" }}
        />
        <img
          id="right"
          className={style.rightImg}
          src="./game2/right.svg"
          alt="right icon"
          style={{ width: "3vw" }}
        />
        <img
          id="wrong"
          className={style.wrongImg}
          src="./game2/wrong.svg"
          alt="wrong icon"
          style={{ width: "3vw" }}
        />
      </div>
    );
  } else
    return (
      <div
        className={style.floatDraggableItem}
        style={{
          position: "absolute",
          pointerEvents: "none",
          left: clientOffset.x - rect.x,
          top: clientOffset.y - rect.y,
        }}
      >
        <span style={{ fontFamily: getFontFamily(item.item.type) }}>
          {item.item.value}
        </span>
        <img
          id="right"
          className={style.rightImg}
          src="./game2/right.svg"
          alt="right icon"
        />
        <img
          id="wrong"
          className={style.wrongImg}
          src="./game2/wrong.svg"
          alt="wrong icon"
        />
      </div>
    );
};

const DraggableItem = ({
  item,
  location,
  id,
  dragEnabled,
  audioPlayingIndex,
  set_audioPlayingIndex,
}) => {
  const { load } = useGlobalAudioPlayer();
  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemType,
    item: { item, location },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => dragEnabled,
  });

  console.log("isDragging", isDragging); //為什麼明明在拖動這個item的時候 isDragging卻有時候會顯示成false

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: false });
  }, [preview]);

  if (item.type === "voice") {
    return (
      <div
        ref={drag}
        id={id}
        className={style.draggableItem}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        onClick={(e) => {
          set_audioPlayingIndex(item.id);
          load(`${AppConfig.baseUrl}${item.value}`, {
            autoplay: true,
            volume: 1,
            onend: (e) => {
              console.log("done");
              set_audioPlayingIndex(-1);
            },
          });
        }}
      >
        {item.id === audioPlayingIndex ? (
          <div style={{ width: "2vw", paddingRight: "1vw" }}>
            <LottieAnimation color="blue" />
          </div>
        ) : (
          <img
            className={style.audioImg}
            src="./comm/play2.svg"
            alt="pay 2 icon"
          />
        )}
        <span>{`選項${item.number}`}</span>
        <img
          id="right"
          className={style.rightImg}
          src="./game2/right.svg"
          alt="right icon"
        />
        <img
          id="wrong"
          className={style.wrongImg}
          src="./game2/wrong.svg"
          alt="wrong icon"
        />
      </div>
    );
  } else if (["img"].includes(item.type)) {
    return (
      <div
        ref={drag}
        id={id}
        className={`${style.draggableItem} ${style.imgType}`}
        style={{ opacity: isDragging ? 0.5 : 1 }}
      >
        <img
          src={`${AppConfig.baseUrl}${item.value}`}
          alt="item value"
          style={{ pointerEvents: "none" }}
        />
        <img
          id="right"
          className={style.rightImg}
          src="./game2/right.svg"
          alt="right icon"
          style={{ width: "3vw" }}
        />
        <img
          id="wrong"
          className={style.wrongImg}
          src="./game2/wrong.svg"
          alt="wrong icon"
          style={{ width: "3vw" }}
        />
      </div>
    );
  } else if (["text", "text_mps", "mps"].includes(item.type)) {
    return (
      <div
        ref={drag}
        id={id}
        className={style.draggableItem}
        style={{ opacity: isDragging ? 0.5 : 1 }}
      >
        <span
          style={{
            fontFamily: getFontFamily(item.type),
            pointerEvents: "none",
          }}
        >
          {item.value}
        </span>
        <img
          id="right"
          className={style.rightImg}
          src="./game2/right.svg"
          alt="right icon"
        />
        <img
          id="wrong"
          className={style.wrongImg}
          src="./game2/wrong.svg"
          alt="wrong icon"
        />
      </div>
    );
  } else {
    return null;
  }
};

export default memo(Game2);
