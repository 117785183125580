import { useState, useEffect } from "react";

import { useGlobalAudioPlayer, useAudioPlayer } from "react-use-audio-player";

import style from "./Result.module.scss";
import { BackgroundDiv } from "../../comm/global/Styleds";
import AppConfig from "../../AppConfig";
import { getGrade } from "../../comm/comm";
import ApngImg from "../../comm/global/components/ApngImg";
import { syncService } from "../../comm/global/services/SyncService";
import { SyncEvent } from "../../comm/global/SyncEvent";

const easeOutCubic = (t) => 1 - Math.pow(1 - t, 3);

const Result = ({
  mute,
  prev_stage,
  questionNum,
  rightCount,
  timeLimit,
  time,
  data,
  restartGameFromResult,
  enterHistory,
}) => {
  const { load: load1 } = useGlobalAudioPlayer();
  const { load: load2 } = useAudioPlayer();

  const [step, ste_step] = useState(0);
  const scoreRank = getGrade(
    questionNum,
    rightCount,
    timeLimit.current,
    timeLimit.current - time / 1000,
  );

  useEffect(() => {
    if (prev_stage.current === "game") {
      if (data.hasTimer === "1") {
        ste_step(0);
        load1("./audio/result.wav", {
          autoplay: true,
          volume: mute ? 0 : 1,
          onend: (e) => {
            console.log("done");
          },
        });
        setTimeout(() => {
          ste_step(1);
          load2("./audio/score.wav", {
            autoplay: true,
            volume: mute ? 0 : 1,
            onend: (e) => {
              ste_step(2);
              load1("./audio/" + scoreRank + ".wav", {
                autoplay: true,
                volume: mute ? 0 : 1,
              });
            },
          });
        }, 1000);
      } else {
        ste_step(2);
        load1("./audio/" + scoreRank + ".wav", {
          autoplay: true,
          volume: mute ? 0 : 1,
        });
      }
    } else {
      ste_step(2);
    }
  }, [data.hasTimer, load1, load2, mute, prev_stage, scoreRank]);

  return (
    <div className={style.result}>
      <BackgroundDiv
        className={style.resultBg}
        img={`./game${data.type}/result_bg.png`}
      >
        <div className={style.rankBox}>
          <div className={style.label}>
            <div>
              <img src="./game1/star.svg" alt="start icon" />
              <span>成績</span>
            </div>
          </div>
          {step >= 2 && (
            <div className={style.rank}>
              <ApngImg
                src={`./comm/${scoreRank}.png`}
                loopTimes={1}
                delaySec={0}
                autoPlay={prev_stage.current === "game"}
              />
            </div>
          )}
        </div>
        {data.hasTimer === "1" && (
          <div className={style.timeBox}>
            <div className={style.label}>
              <div>
                <img src="./game1/timer.svg" alt="timer icon" />
                <span>時間</span>
              </div>
            </div>
            {step >= 1 && (
              <div>
                <Counter
                  value={(timeLimit.current - time / 1000).toFixed(1)}
                  duration={3}
                  prev_stage={prev_stage}
                  step={step}
                />
                <span className={style.span3} data-storke="秒">
                  秒
                </span>
              </div>
            )}
          </div>
        )}
        <img
          className={style.light}
          src="./game1/lightbeam.png"
          alt="light beam"
        />
        <BackgroundDiv className={style.title} img={`./game1/resultTitle.png`}>
          <span>遊戲結果</span>
        </BackgroundDiv>
      </BackgroundDiv>
      <div className={style.btns}>
        <div
          className={style.viewHistory}
          onClick={() => {
            if (AppConfig.debug === true || step === 2) {
              enterHistory();
              syncService.sendMessage(SyncEvent.ENTER_HISTORY);
            }
          }}
        >
          <span>查看答案</span>
        </div>
        <div
          className={style.replay}
          onClick={(e) => {
            if (step === 2) {
              restartGameFromResult();
              syncService.sendMessage(SyncEvent.RESTART_GAME_FROM_RESULT);
            }
          }}
        >
          <span>再玩一次</span>
        </div>
      </div>
      <img src="./game1/Robot_floating.png" alt="robot floating" />
    </div>
  );
};

const Counter = ({ value, duration, prev_stage, step }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (prev_stage.current === "game" && step === 1) {
      let start = 0;
      const end = parseFloat(value);
      const startTime = performance.now();

      const step = (currentTime) => {
        const elapsed = (currentTime - startTime) / 1000; // 转换为秒
        const progress = Math.min(elapsed / duration, 1); // 计算进度百分比
        const easedProgress = easeOutCubic(progress); // 使用缓动函数
        let currentCount = start + easedProgress * (end - start);
        if (currentCount < 0) currentCount = 0;
        setCount(currentCount.toFixed(1)); // 保留一位小数

        if (progress < 1) {
          requestAnimationFrame(step);
        } else {
          setCount(end.toFixed(1));
        }
      };

      requestAnimationFrame(step);
    } else setCount(value);
  }, [duration, value, prev_stage, step]);

  return (
    <span className={style.span2} data-storke={count}>
      {count}
    </span>
  );
};

export default Result;
