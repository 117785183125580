import { useState, useEffect } from "react";

import style from "./History4.module.scss";
import { BackgroundDiv } from "../../comm/global/Styleds";
import AppConfig from "../../AppConfig";
import { getFontFamily } from "../../comm/comm";
import Game14VoiceBtn from "../../comm/global/components/Game14VoiceBtn";
import { syncService } from "../../comm/global/services/SyncService";
import { SyncEvent } from "../../comm/global/SyncEvent";

const defaultHistory = [
  { isCorrect: true, questionIndex: 4, selOptionId: 5 },
  { isCorrect: true, questionIndex: 5, selOptionId: 6 },
  { isCorrect: true, questionIndex: 6, selOptionId: 7 },
  { isCorrect: false, questionIndex: 3, selOptionId: 5 },
  { isCorrect: false, questionIndex: 2, selOptionId: 4 },
  { isCorrect: true, questionIndex: 1, selOptionId: 2 },
  { isCorrect: true, questionIndex: 0, selOptionId: 1 },
];

const History4 = ({
  gameData,
  anserHistory = defaultHistory,
  allQuestion,
  openAnswerDetail,
  enterResultFromHistory,
}) => {
  const [audioPlayingIndex, set_audioPlayingIndex] = useState("");

  useEffect(() => {
    console.log(gameData, anserHistory, allQuestion);
  }, [gameData, anserHistory, allQuestion]);

  return (
    <div className={style.history}>
      <BackgroundDiv className={style.bg} img="./game4/history_bg.png">
        <div className={style.title}>
          <span>正確答案</span>
        </div>
        <div className={style.panel}>
          <div className={style.anserPicBox}>
            <img
              src={`${AppConfig.baseUrl}${allQuestion[0].questionAnswerPicture}`}
              className={style.anserPic}
              alt="question answer"
            />
          </div>
          <div className={style.content}>
            {anserHistory.map((v, k) => {
              const _question = allQuestion[v.questionIndex];
              return (
                <div className={style.item} key={k}>
                  {/* 1 */}
                  <span className={style.number}>{`${k + 1}.`}</span>
                  {/* 2 */}
                  <img
                    className={style.icon}
                    src={v.isCorrect ? "./comm/v.svg" : "./comm/x.svg"}
                    alt="is correct icon"
                  />
                  <QuestionDiv
                    type={_question?.descriptionType}
                    subType={_question[`optionType1`]}
                    value={_question?.questionDescription}
                    index={k}
                    audioPlayingIndex={audioPlayingIndex}
                    set_audioPlayingIndex={set_audioPlayingIndex}
                  />
                  <AnswerDiv
                    type={_question[`optionType1`]}
                    subType={_question?.descriptionType}
                    value={_question[`option${_question.questionAnswerData}`]}
                    index={k}
                    audioPlayingIndex={audioPlayingIndex}
                    set_audioPlayingIndex={set_audioPlayingIndex}
                  />
                  <div
                    className={style.detailBtn}
                    onClick={() => {
                      openAnswerDetail(k);
                      syncService.sendMessage(SyncEvent.OPEN_ANSWER_DETAIL, k);
                    }}
                  >
                    <img src="./comm/zoom_solid.svg" alt="zoom icon" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </BackgroundDiv>
      <div className={style.backDiv}>
        <div
          className={style.back}
          onClick={() => {
            enterResultFromHistory();
            syncService.sendMessage(SyncEvent.ENTER_RESULT_FROM_HISTORY);
          }}
        >
          <span>返回</span>
        </div>
      </div>
    </div>
  );
};

const QuestionDiv = ({
  type,
  subType,
  value,
  index,
  audioPlayingIndex,
  set_audioPlayingIndex,
}) => {
  if (["text", "text_mps", "mps"].includes(type)) {
    return (
      <span
        className={`${style.questionText} ${["img", "voice"].includes(subType) ? style.scale : ""}`}
        style={{ fontFamily: getFontFamily(type) }}
      >
        {value}
      </span>
    );
  } else if (type === "img") {
    return (
      <img
        className={style.questionImg}
        src={`${AppConfig.baseUrl}${value}`}
        alt="base url value"
      />
    );
  } else if (type === "voice") {
    return (
      <Game14VoiceBtn
        type="question"
        value={value}
        index={index}
        audioPlayingIndex={audioPlayingIndex}
        set_audioPlayingIndex={set_audioPlayingIndex}
      />
    );
  }
};

const AnswerDiv = ({
  type,
  subType,
  value,
  index,
  audioPlayingIndex,
  set_audioPlayingIndex,
}) => {
  if (["text", "text_mps", "mps"].includes(type)) {
    return (
      <span
        className={`${style.answerText} ${["img", "voice"].includes(subType) ? style.scale : ""}`}
        style={{ fontFamily: getFontFamily(type) }}
      >
        {value}
      </span>
    );
  } else if (type === "img") {
    return (
      <img
        className={style.answerImg}
        src={`${AppConfig.baseUrl}${value}`}
        alt="base url value"
      />
    );
  } else if (type === "voice") {
    return (
      <Game14VoiceBtn
        type="answer"
        value={value}
        index={index}
        audioPlayingIndex={audioPlayingIndex}
        set_audioPlayingIndex={set_audioPlayingIndex}
      />
    );
  }
};

export default History4;
