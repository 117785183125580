/** @typedef {(event: string, payload: unknown) => void} OnMessageCallback */

class SyncService {
  /** @type {Set<OnMessageCallback>} */
  _onMessageCallbacks = new Set();

  /**
   * @returns {() => void} cleanup function
   */
  init() {
    window.parent.postMessage(
      {
        kind: "RegisterMagixEvent",
        payload: "sync-interactive-game-state",
      },
      "*",
    );

    /**
     * On message handler
     * @param {MessageEvent} event
     */
    const handler = (event) => {
      if (
        event.data.kind === "ReciveMagixEvent" &&
        event.data.payload.event === "sync-interactive-game-state"
      ) {
        const { innerEvent, innerPayload } = event.data.payload.payload;
        this._handleMessage(innerEvent, innerPayload);
      }
    };

    window.addEventListener("message", handler);

    return () => {
      window.removeEventListener("message", handler);
    };
  }

  _handleMessage(event, payload) {
    this._onMessageCallbacks.forEach((callback) => {
      callback(event, payload);
    });
  }

  sendMessage(event, payload) {
    window.parent.postMessage(
      {
        kind: "DispatchMagixEvent",
        payload: {
          event: "sync-interactive-game-state",
          payload: { event, payload },
        },
      },
      "*",
    );
  }

  /**
   * @param {OnMessageCallback} callback
   * @returns Cleanup function
   */
  onMessage(callback) {
    this._onMessageCallbacks.add(callback);
    return () => this._onMessageCallbacks.delete(callback);
  }
}

export const syncService = new SyncService();
