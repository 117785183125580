var AppConfig = module.exports;
AppConfig.debug = false;
//AppConfig.speedMincroSec=100;
AppConfig.speedMincroSec = 1;
AppConfig.baseUrl = "https://storage.interactive-learning.oneclass.com.tw";
AppConfig.type = [
  {
    name: "問答遊戲",
  },
  {
    name: "分類配對遊戲",
  },
  {
    name: "排序遊戲",
  },
  {
    name: "標籤圖表遊戲",
  },
];
