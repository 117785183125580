import {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
  useMemo,
} from "react";
import style from "./Game14VoiceBtn.module.scss";
import AppConfig from "../../../AppConfig";
import { useGlobalAudioPlayer } from "react-use-audio-player";
import LottieAnimation from "./LottieAnimation";
const Game14VoiceBtn = ({
  type,
  value,
  index,
  audioPlayingIndex,
  set_audioPlayingIndex,
  customStyle,
}) => {
  const { load } = useGlobalAudioPlayer();
  useEffect(() => {}, []);
  return (
    <div
      style={customStyle}
      className={`${type === "question" ? style.questionVoice : style.answerVoice}`}
      src="./comm/volume.svg"
      onClick={(e) => {
        set_audioPlayingIndex(index);
        load(`${AppConfig.baseUrl}${value}`, {
          autoplay: true,
          volume: 1,
          onend: (e) => {
            set_audioPlayingIndex("");
          },
        });
      }}
    >
      {index === audioPlayingIndex ? (
        <div style={{ width: "2vw" }}>
          <LottieAnimation color={type === "question" ? "white" : "brown"} />
        </div>
      ) : (
        <img
          src={
            type === "question"
              ? "./comm/playquestion.svg"
              : "./comm/answerPlay.svg"
          }
        />
      )}
      <span>{type === "question" ? "播放題目" : "播放答案"}</span>
    </div>
  );
};

export default Game14VoiceBtn;
