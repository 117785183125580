import styled from "styled-components";
export const customStyles = {
  overlay: {
    backgroundColor: "rgba(127, 127, 127, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
    border: "none",
    borderRadius: "0px",
    minWidth: "1040px",
    minHeight: "434px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
};
export const customStyles2 = {
  overlay: {
    backgroundColor: "rgba(127, 127, 127, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
    border: "none",
    borderRadius: "0px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "90vh",
    overflowY: "scroll",
    boxSizing: "border-box",
  },
};

export const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(127, 127, 127, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
    border: "none",
    borderRadius: "0px",
    width: "80%",
    height: "434px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const previewModalStyles = {
  overlay: {
    backgroundColor: "rgba(127, 127, 127, 0.5)",
  },
  content: {
    position: " absolute",
    inset: " 50% auto auto 50%",
    border: " none",
    background: " rgb(255, 255, 255)",
    overflow: " visible",
    borderRadius: "0px",
    outline: " none",
    padding: " 5px 20px",
    transform: " translate(-50%, -50%)",
    width: " 80%",
    height: " 80vh",
    display: " flex",
    flexDirection: "column",
  },
};
export const ProductImg = styled.div`
  & {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-image: url("${(props) => props.img}");
    background-repeat: no-repeat;
    background-position: top center;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    box-sizing: border-box;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    padding: 30px;
  }
  &:hover {
    border: 3px solid #000000;
  }
`;

export const SelProductImg = styled.div`
  width: ${(props) => (props.isMobile ? "100%" : "372px")};
  height: 268px;
  background-size: cover;
  background-image: url("${(props) => props.img}");
  background-repeat: no-repeat;
  background-position: center;
  margin-right: ${(props) => (props.isMobile ? "0px" : "80px")};
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export const SelectDiv = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 47px;
  width: ${(props) => props.width}px;
  height: 46px;
  border: 1px solid #d5d5d5;
  align-items: center;
  background-color: white;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
`;

export const Ball = styled.div`
  width: 50px;
  height: 50px;
  background-size: contain;
  background-position: center;
  background-image: url("./assets/${(props) => props.img}.png");
  background-repeat: no-repeat;
  margin-top: 2px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  width: 355px;
  ${(props) => (props.border ? "border-bottom: 1px solid #7F7F7F;" : "")}

  padding: 11px 20px;
  align-items: center;
  div {
    padding-left: 14px;
    p {
      margin: 0px;
      padding: 0px;
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
  }
`;

export const LoadingBar = styled.div`
  height: 100%;
  width: ${(props) => props.process}%;
  color: #000 !important;
  background: linear-gradient(270deg, #fecc86 0%, #d49234 100%);
`;

export const DivH = styled.div`
  width: 100%;
  height: ${(props) => props.Hpx}px;
`;

export const BackgroundDiv = styled.div`
    &{
    background-image:url('${(props) => props.img}');
    background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
    `;
